/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
html {
  scroll-behavior: smooth !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #02314B !important;
}
body {
  min-width: 400px !important;
}
a,
a:hover {
  color: #ffffff;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
#homepage-images {
  margin: 0px !important;
}
#homepage-images .ui.grid {
  margin-top: 1rem;
}
#homepage-images .ui.card {
  height: 100%;
}
#homepage-images .ui.card .image {
  position: relative;
  height: 300px;
  /* Fixed height for consistency */
  overflow: hidden;
}
#homepage-images .ui.card .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#homepage-images .overlay-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #65a9cec9;
  padding: 1em;
  /* Fixed height for the overlay */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 105px;
  transition: height 0.5s ease-in-out;
}
@media screen and (max-width: 1125px) {
  #homepage-images .overlay-content {
    height: 130px;
  }
}
@media screen and (max-width: 1050px) {
  #homepage-images .overlay-content {
    height: 150px;
  }
}
@media screen and (max-width: 767px) {
  #homepage-images .overlay-content {
    height: 100px;
  }
}
#homepage-images .overlay-content .header {
  color: #3498db;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.2em;
  color: white;
}
#homepage-images .overlay-content .description {
  color: white;
  font-size: 0.9em;
  line-height: 1.4;
  overflow: visible;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
#homepage-news h2 {
  margin: 0;
  padding: 0;
}
#homepage-news .row {
  padding: 0;
}
#homepage-four-buttons div > div {
  margin-top: 0;
}
#homepage-four-buttons div {
  padding: 0 2px;
}
#homepage-four-buttons button {
  height: 3.5rem;
  padding: 0;
  background: #65a9ce;
  color: white;
}
#homepage-four-buttons .link {
  text-align: center;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #65a9ce;
  border-radius: 5px;
  margin-inline: 5px;
  margin-bottom: 14px;
}
#homepage-four-buttons .link a {
  color: white;
  font-weight: 700;
}
#directives-org-chart {
  /*** PRIMARY ***/
  /*** SECONDARY ***/
  /*** TERTIARY ***/
  /*** QUATERNARY ***/
  /*** QUINARY ***/
  /*** MEDIA QUERIES ***/
}
#directives-org-chart #wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 80em;
}
#directives-org-chart #container {
  float: left;
  padding: 1em;
  width: 100%;
}
#directives-org-chart ol.organizational-chart,
#directives-org-chart ol.organizational-chart ol,
#directives-org-chart ol.organizational-chart li,
#directives-org-chart ol.organizational-chart li > div {
  position: relative;
}
#directives-org-chart ol.organizational-chart,
#directives-org-chart ol.organizational-chart ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
#directives-org-chart ol.organizational-chart {
  text-align: center;
}
#directives-org-chart ol.organizational-chart ol {
  padding-top: 1em;
}
#directives-org-chart ol.organizational-chart ol:before,
#directives-org-chart ol.organizational-chart ol:after,
#directives-org-chart ol.organizational-chart li:before,
#directives-org-chart ol.organizational-chart li:after,
#directives-org-chart ol.organizational-chart > li > div:before,
#directives-org-chart ol.organizational-chart > li > div:after {
  background-color: #678099;
  content: '';
  position: absolute;
}
#directives-org-chart ol.organizational-chart ol > li {
  padding: 1em 0 0 1em;
}
#directives-org-chart ol.organizational-chart > li ol:before {
  height: 1em;
  left: 50%;
  top: 0;
  width: 3px;
}
#directives-org-chart ol.organizational-chart > li ol:after {
  height: 3px;
  left: 3px;
  top: 1em;
  width: 50%;
}
#directives-org-chart ol.organizational-chart > li ol > li:not(:last-of-type):before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}
#directives-org-chart ol.organizational-chart > li ol > li:not(:last-of-type):after {
  height: 100%;
  left: 0;
  top: 0;
  width: 3px;
}
#directives-org-chart ol.organizational-chart > li ol > li:last-of-type:before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}
#directives-org-chart ol.organizational-chart > li ol > li:last-of-type:after {
  height: 2em;
  left: 0;
  top: 0;
  width: 3px;
}
#directives-org-chart ol.organizational-chart li > div {
  background-color: #fff;
  border-radius: 3px;
  min-height: 2em;
  padding: 0.5em;
}
#directives-org-chart ol.organizational-chart > li > div {
  background-color: #f7fcff;
  border: 1px solid;
  margin-right: 1em;
  height: auto;
}
#directives-org-chart ol.organizational-chart > li > div span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 8px;
}
#directives-org-chart ol.organizational-chart > li > div:before {
  bottom: 2em;
  height: 3px;
  right: -1em;
  width: 1em;
}
#directives-org-chart ol.organizational-chart > li > div:first-of-type:after {
  bottom: 0;
  height: 2em;
  right: -1em;
  width: 3px;
}
#directives-org-chart ol.organizational-chart > li > div + div {
  margin-top: 1em;
}
#directives-org-chart ol.organizational-chart > li > div + div:after {
  height: calc(100% + 1em);
  right: -1em;
  top: -1em;
  width: 3px;
}
#directives-org-chart ol.organizational-chart > li > ol:before {
  left: inherit;
  right: 0;
}
#directives-org-chart ol.organizational-chart > li > ol:after {
  left: 0;
  width: 100%;
}
#directives-org-chart ol.organizational-chart > li > ol > li > div {
  border: 1px solid;
}
#directives-org-chart ol.organizational-chart > li > ol > li > ol > li > div {
  border: 1px solid;
}
#directives-org-chart ol.organizational-chart > li > ol > li > ol > li > ol > li > div {
  border: 1px solid;
}
#directives-org-chart ol.organizational-chart > li > ol > li > ol > li > ol > li > ol > li > div {
  border: 1px solid;
}
@media only screen and (min-width: 64em) {
  #directives-org-chart {
    /* PRIMARY */
    /* SECONDARY */
  }
  #directives-org-chart ol.organizational-chart {
    margin-left: -1em;
    margin-right: -1em;
  }
  #directives-org-chart ol.organizational-chart > li > div {
    display: inline-block;
    float: none;
    margin: 0 1em 1em 1em;
    vertical-align: bottom;
  }
  #directives-org-chart ol.organizational-chart > li > div:only-of-type {
    margin-bottom: 0;
    width: calc((100% / 1) - 2em - 4px);
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(2),
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(2) ~ div {
    width: calc((100% / 2) - 2em - 4px);
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(3),
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(3) ~ div {
    width: calc((100% / 3) - 2em - 4px);
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(4),
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(4) ~ div {
    width: calc((100% / 4) - 2em - 4px);
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(5),
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:nth-last-of-type(5) ~ div {
    width: calc((100% / 5) - 2em - 4px);
  }
  #directives-org-chart ol.organizational-chart > li > div:before,
  #directives-org-chart ol.organizational-chart > li > div:after {
    bottom: -1em !important;
    top: inherit !important;
  }
  #directives-org-chart ol.organizational-chart > li > div:before {
    height: 1em !important;
    left: 50% !important;
    width: 3px !important;
  }
  #directives-org-chart ol.organizational-chart > li > div:only-of-type:after {
    display: none;
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:not(:only-of-type):after,
  #directives-org-chart ol.organizational-chart > li > div:last-of-type:not(:only-of-type):after {
    bottom: -1em;
    height: 3px;
    width: calc(50% + 1em + 3px);
  }
  #directives-org-chart ol.organizational-chart > li > div:first-of-type:not(:only-of-type):after {
    left: calc(50% + 3px);
  }
  #directives-org-chart ol.organizational-chart > li > div:last-of-type:not(:only-of-type):after {
    left: calc(-1em - 3px);
  }
  #directives-org-chart ol.organizational-chart > li > div + div:not(:last-of-type):after {
    height: 3px;
    left: -2em;
    width: calc(100% + 4em);
  }
  #directives-org-chart ol.organizational-chart > li > ol {
    display: flex;
    flex-wrap: nowrap;
  }
  #directives-org-chart ol.organizational-chart > li > ol:before,
  #directives-org-chart ol.organizational-chart > li > ol > li:before {
    height: 1em !important;
    left: 50% !important;
    top: 0 !important;
    width: 3px !important;
  }
  #directives-org-chart ol.organizational-chart > li > ol:after {
    display: none;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li {
    flex-grow: 1;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li:only-of-type {
    padding-top: 0;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li:only-of-type:before,
  #directives-org-chart ol.organizational-chart > li > ol > li:only-of-type:after {
    display: none;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li:first-of-type:not(:only-of-type):after,
  #directives-org-chart ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    height: 3px;
    top: 0;
    width: 50%;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li:first-of-type:not(:only-of-type):after {
    left: 50%;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    left: 0;
  }
  #directives-org-chart ol.organizational-chart > li > ol > li + li:not(:last-of-type):after {
    height: 3px;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.container.directives-flowdown-table {
  overflow: auto;
  height: 20rem;
  scroll-behavior: smooth;
}
.container.directives-flowdown-table tr:target {
  background-color: #f4f485;
}
.container.directives-flowdown-table tr:target h3 {
  font-size: 1.6rem;
  border-bottom: 5px solid #bdbd54;
  width: -moz-max-content;
  width: max-content;
}
.directives-flowdown {
  --base-color: 202, 70%;
}
.directives-flowdown .column {
  display: flex !important;
  flex-direction: row;
  border-radius: 5px;
}
@media screen and (max-width: 1050px) {
  .directives-flowdown .column {
    flex-direction: column;
  }
}
.directives-flowdown .step {
  flex: auto;
  align-items: baseline;
  margin: 0 0.4px;
  padding: 10px 20px;
  font-size: large;
  font-weight: 500;
}
.directives-flowdown .step:after {
  background-color: inherit;
  display: block;
  position: absolute;
  z-index: 2;
  content: '';
  top: 50%;
  right: 0%;
  width: 1.125em;
  height: 1.125em;
  border-style: solid;
  border-color: white;
  border-width: 0px 1px 1px 0px;
  transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
@media screen and (max-width: 1050px) {
  .directives-flowdown .step:after {
    bottom: -21px;
    top: unset;
    right: 4%;
    transform: translateY(-50%) translateX(50%) rotate(45deg);
  }
}
.directives-flowdown .step:last-child:after {
  display: none;
}
.directives-flowdown .step a {
  color: white;
  cursor: pointer;
}
.directives-flowdown .step:nth-child(1) {
  background-color: hsl(var(--base-color), 60%);
}
.directives-flowdown .step:nth-child(2) {
  background-color: hsl(var(--base-color), 55%);
}
.directives-flowdown .step:nth-child(3) {
  background-color: hsl(var(--base-color), 50%);
}
.directives-flowdown .step:nth-child(4) {
  background-color: hsl(var(--base-color), 45%);
}
.directives-flowdown .step:nth-child(5) {
  background-color: hsl(var(--base-color), 40%);
}
.directives-flowdown .step:nth-child(6) {
  background-color: hsl(var(--base-color), 32%);
}
.directives-flowdown .step:nth-child(7) {
  background-color: hsl(var(--base-color), 27%);
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.tabs-block .ui.menu {
  flex-wrap: wrap;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#news-view-1 a {
  font-size: inherit;
}
#news-view-1 div {
  margin-bottom: 1.5rem;
}
#news-view-1 span,
#news-view-1 #more-link {
  float: inline-end;
  font-size: 0.88em;
}
